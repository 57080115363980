define("comunidades-nafin/components/webinars-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    registros: [],
    init: function init() {
      this._super.apply(this, arguments);

      var self = this;
      moment.updateLocale('es', {
        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      });

      if (window.instanciaConfiguracion.inscripcion_webinars_bigmarker) {
        $.ajax({
          url: constant.APIURL + 'webinars/registrosUsuario',
          type: 'GET',
          dataType: "json",
          async: false,
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          success: function success(r) {
            if (r.success) self.registros = r.data;
          },
          error: function error(e) {
            console.log(e);
          }
        });
        self.set("registro_directo", true);
      } else {
        self.set("registro_directo", false);
      }

      $.ajax({
        url: constant.APIURL + 'webinars/lista',
        type: 'GET',
        dataType: "json",
        async: false,
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        success: function success(r) {
          if (r.success) {
            Object.keys(r.next).forEach(function (key) {
              for (var i = 0; i < self.registros.length; i++) {
                if (self.registros[i].conference_id == r.next[key].id) {
                  r.next[key].suscribed = true;
                  r.next[key].urlAcceso = self.registros[i].url;
                  r.next[key].registrant_id = self.registros[i].registrant_id;
                }
              }
            });
            self.set('next_webinars', r.next.sort(function (a, b) {
              return new Date(a.start_time) - new Date(b.start_time);
            }));
            self.set('previous_webinars', r.prev.sort(function (a, b) {
              return new Date(b.start_time) - new Date(a.start_time);
            }));
            $(".text-webinar").addClass("selected-option-menu");
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
      $('#loading').fadeOut('slow');
      setTimeout(function () {
        $(".text-webinar").addClass("selected-option-menu");
      }, 2000);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set("webinar_state", true);
      var self = this;
      $(".text-webinar").addClass("selected-option-menu");
    },
    actions: {
      registrar: function registrar(conference) {
        $("#webinar_btn_" + conference.id).hide();
        $.ajax({
          url: constant.APIURL + 'webinars/registro',
          type: 'POST',
          dataType: "json",
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: {
            webinar_id: conference.id,
            usuario_k: window.datosPersonales.usuario_k,
            first_name: window.datosPersonales.nombre,
            email: window.datosPersonales.email,
            nombre: conference.topic,
            inicio: conference.start_time,
            fin: "",
            channel_id: "",
            url: conference.join_url
          },
          success: function success(r) {
            if (r.success) $('#modal-avisos .modal-header').text('Aviso');else $('#modal-avisos .modal-header').text('Error');
            $('#modal-avisos .modal-body').html(r.mensaje);
            $('#modal-avisos').modal('show');
            $("button#cerrar").click(function () {
              window.location.reload();
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      cancelar: function cancelar(webinar, registrant_id) {
        $("#webinar_btn_" + webinar.id).hide();
        $.ajax({
          url: constant.APIURL + 'webinars/cancelar',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: {
            webinar_id: webinar.id,
            email: window.datosPersonales.email,
            registrant_id: registrant_id
          },
          success: function success(r) {
            $('#modal-avisos .modal-header').text('Aviso');
            $('#modal-avisos .modal-body').html("Registro cancelado con éxito");
            $('#modal-avisos').modal('show');
            $("button#cerrar").click(function () {
              window.location.reload();
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      anchorTo: function anchorTo(path) {
        //event.preventDefault();
        var target_offset = $("#" + path).offset();
        var target_top = target_offset.top;
        $('html, body').animate({
          scrollTop: target_top
        }, 1500, 'easeInSine'); ///alert(document.location.href);
        //document.location.href = document.location.href.split("#")[0] + "#" + path;
        //return false;
      },
      viewmore: function viewmore(event) {
        $(event.target).closest(".webinar-details").find(".hidden-element").removeClass("hidden-standalone-view-more");
        $(event.target).closest(".webinar-details").find(".ocultar-ver-mas").removeClass("d-none");
        $(event.target).addClass("d-none");
        $.ajax({
          url: constant.APIURL + 'webinars/presentadores',
          type: 'post',
          data: {
            webinar_id: $(event.target).data("id")
          },
          dataType: "json",
          async: false,
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          success: function success(r) {
            var presentadores = "";

            if (r.success) {
              if (r.data.panelists.length) $.each(r.data.panelists, function (i, v) {
                presentadores += "<b>Nombre: </b>" + v.name + "<br>";
                presentadores += "<b>Email: </b>" + v.email + "<br><br>";
              });else presentadores = "<div class='alert alert-info text-center'>Información no disponible por el momento</div>";
              $(event.target).closest(".webinar-details").find(".presentadores-list").html(presentadores);
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      hideviewmore: function hideviewmore(event) {
        $(event.target).closest(".webinar-details").find(".hidden-element").addClass("hidden-standalone-view-more");
        $(event.target).closest(".webinar-details").find(".ocultar-ver-mas").addClass("d-none");
        $(event.target).closest(".webinar-details").find(".ver-mas").removeClass("d-none");
      },
      new_webinar: function new_webinar() {
        this.set("webinar_state", true);
      },
      old_webinar: function old_webinar() {
        this.set("webinar_state", false);
      },
      inscribir: function inscribir(url) {
        if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
          var options = "";

          if (device.platform == "Android") {
            options = 'location=yes,hidden=no,closebuttoncaption=Volver a webinars,hidenavigationbuttons=yes,hideurlbar=yes,footer=no,footercolor=' + window.color_theme;
          } else {
            options = 'location=no,hidden=no,closebuttoncaption=Volver a webinars,hidenavigationbuttons=yes,hideurlbar=yes,footer=false,footercolor=' + window.color_theme;
          }

          var pageContentUrl = url;
          var ref = cordova.InAppBrowser.open(pageContentUrl, '_system', options);
          ref.addEventListener('loadstart', function (event) {});
          ref.addEventListener('loadstop', function (event) {});
          ref.addEventListener('exit', function (event) {});
        } else {
          window.open(url, "_blank");
        }
      },
      getwebinarvideo: function getwebinarvideo(webinar) {
        this.set("webinarName", webinar.topic);
        $("#modal-webinar").modal("show");
        $(".modal-dialog").find(".cargando").html('');
        var iframe = '<video controls autoplay width="100%" height="auto" src="' + webinar.download_url + '"></video>';
        $(".modal-dialog").find("#ifr").html(iframe);
        $(".modal-dialog").find("#ifr").find("iframe").addClass("iframe-fullwidth");
      },
      endVideo: function endVideo() {
        $(".modal-dialog").find("#ifr").html("");
        $("#modal-webinar").modal("hide");
      }
    }
  });

  _exports.default = _default;
});