define("comunidades-nafin/components/comunidades-movil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    verMasComunidadesAutoinscribibles: true,
    didRender: function didRender() {
      localStorage.setItem('seccion-anterior', Ember.$(".titulo-seccion").text());
    },
    router: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      localStorage.setItem('seccion-anterior', Ember.$(".titulo-seccion").text());
      Ember.$(".text-cursos").addClass("selected-option-menu");
      Ember.$("body").addClass("panel-gris");
      Ember.$("#panel").addClass("panel-gris");
      Ember.$.ajax({
        url: constant.APIURL + 'comunidad/listarMisComunidades',
        type: 'GET',
        contentType: 'application/x-www-form-urlencoded',
        data: '',
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          var respons = JSON.parse(r);
          Object.keys(respons.data).forEach(function (idx) {
            if (respons.data[idx].img.indexOf('http') != -1) {
              respons.data[idx].img = respons.data[idx].img;
            } else {
              respons.data[idx].img = constant.BASEURL + respons.data[idx].img;
            }

            if (respons.data[idx].descripcion.split(' ').length > 22) {
              respons.data[idx].descripcion = respons.data[idx].descripcion.split(' ').slice(0, 22).join(' ') + '...';
            }
          });
          var firstCourses = [];

          for (var i = 0; i < respons.data.length; i++) {
            if (respons.data[i]) {
              firstCourses.push(respons.data[i]);
            }
          }

          self.setComunidades(firstCourses);
          Ember.$('#seeMoreCommunities').click(function () {
            self.setComunidades(respons.data);
            Ember.$('#seeMoreCommunities').remove();
          });
          Ember.$('#loading').fadeOut('slow'); //lazyLoad.update();

          Ember.$.ajax({
            url: constant.APIURL + 'usuario/datospersonales/',
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            xhrFields: {
              withCredentials: true
            },
            success: function success(r) {
              var data = JSON.parse(r);
              /** Redes sociales Comunidad **/

              self.set("comunidades_facebook", true);

              if (data.configuracion.general_compatir_comunidad_en_facebook_habilitar == false) {
                self.set("comunidades_facebook", false);
              }

              self.set("comunidades_twitter", true);

              if (data.configuracion.general_compatir_comunidad_en_twitter_habilitar == false) {
                self.set("comunidades_twitter", false);
              }

              self.set("comunidades_linkedin", true);

              if (data.configuracion.general_compatir_comunidad_en_linkedin_habilitar == false) {
                self.set("comunidades_linkedin", false);
              }
            }
          });
        },
        error: function error(e) {
          console.log(e);
        }
      });
      self.send("getComunidadesAuto", false);
    }
  }, _defineProperty(_EmberComponent$exte, "didRender", function didRender() {
    var self = this._super.apply(this, arguments);
  }), _defineProperty(_EmberComponent$exte, "setComunidades", function setComunidades(r) {
    this.set('comunidades-cards', r);
  }), _defineProperty(_EmberComponent$exte, "setOtherComunidades", function setOtherComunidades(r) {
    this.set('other-comunidades-cards', r);
  }), _defineProperty(_EmberComponent$exte, "actions", {
    cursos: function cursos(comunidad) {
      Ember.$("#loading").show();
      localStorage.setItem("comunidad_k", comunidad.comunidad_k);
      localStorage.setItem("comunidad_nombre", comunidad.nombre);
      localStorage.setItem("comunidad_imagen", comunidad.img);
      document.location.href = document.location.href.split("#")[0] + "#/cursos";
      document.location.reload();
    },
    getComunidadesAuto: function getComunidadesAuto(all) {
      var self = this;
      Ember.$.ajax({
        url: constant.APIURL + 'comunidad/listarOtrasComunidades',
        type: 'GET',
        contentType: 'application/x-www-form-urlencoded',
        data: '',
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          var respons = JSON.parse(r);

          if (respons.data == undefined) {
            Ember.$("#modal_login").modal("show");
            Ember.$(".modal").addClass("backgroundLogin");
          }

          Object.keys(respons.data).forEach(function (idx) {
            if (respons.data[idx].img.indexOf('http') != -1) {
              respons.data[idx].img = respons.data[idx].img;
            } else {
              respons.data[idx].img = constant.BASEURL + respons.data[idx].img;
            }
          });
          var firstCourses = [];
          var to = all ? respons.data.length : 2;

          for (var i = 0; i < to; i++) {
            if (respons.data[i]) {
              firstCourses.push(respons.data[i]);
            }
          }

          if (all || firstCourses.length <= 2) {
            // $('#seeMoreOtherCommunities').remove();
            self.set("verMasComunidadesAutoinscribibles", false);
          }

          Ember.$('#seeMoreOtherCommunities').click(function () {
            self.setOtherComunidades(respons.data);
            Ember.$('#seeMoreOtherCommunities').remove();
          });
          self.setOtherComunidades(firstCourses);
          Ember.$(".text-cursos").addClass("selected-option-menu");
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    suscribe2other: function suscribe2other(comunidad_k) {
      Ember.$.ajax({
        url: constant.APIURL + 'comunidad/inscribirUsuarioAComunidad',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: 'comunidad_k=' + comunidad_k,
        xhrFields: {
          withCredentials: true
        },
        success: function success(r) {
          document.location.href = "/comunidades/" + comunidad_k;
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    facebook: function facebook(e) {
      this._super();

      var self = this;
      var aTagCurso = Ember.$(e.target).parent().parent().parent().parent().parent().children()[0];
      var arrayComunidad = Ember.$(aTagCurso).data('href').split('/');
      var comunidad_k = arrayComunidad[arrayComunidad.length - 1];
      shareFB(constant.SHAREURL + 'comunidad/' + comunidad_k);
      window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_FACEBOOK_COMPARTIR_COMUNIDAD, comunidad_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_FACEBOOK);
    },
    twitter: function twitter(e) {
      this._super();

      var self = this; // Selector a card del elemento dom

      var elementoPadreDom = Ember.$(e.target).parent().parent().parent().parent().parent(); // Elementos hijos de elemento padre del dom

      var arrayChildParents = elementoPadreDom.children();
      var elementoContenido = arrayChildParents[0]; //  selector a elemeto que contiene imagen, titulo, numero de integrantes de la comunidad

      var arrayElementoPadreContenido = Ember.$(elementoContenido).children().children();
      var elementoImagenComunidad = arrayElementoPadreContenido[0]; //Recuperación Imagen fondo de comunidad

      var imagenSrc = Ember.$(elementoImagenComunidad).data('src');
      var elementoCardImgOverlay = arrayElementoPadreContenido[1];
      var arrayChildsCardImgOverlay = Ember.$(elementoCardImgOverlay).children();
      var elementoComunidadTextDom = arrayChildsCardImgOverlay[1];
      var elementoComunidadParticipantes = arrayChildsCardImgOverlay[3];
      var numeroDeParticipatesEnComunidad = Ember.$(elementoComunidadParticipantes).text().trim();
      var nombreDeLaComunidad = Ember.$(elementoComunidadTextDom).text();
      var twitterMsm = 'Forma parte de más de los ' + numeroDeParticipatesEnComunidad + ' que son parte de la comunidad ' + nombreDeLaComunidad + ' en NAFIN te capacita';
      var aTagCurso = Ember.$(e.target).parent().parent().parent().parent().parent().children()[0];
      var arrayComunidad = Ember.$(aTagCurso).data('href').split('/');
      var comunidad_k = arrayComunidad[arrayComunidad.length - 1];
      var url = constant.SHAREURL + 'comunidad/' + comunidad_k;
      var width = 575,
          height = 400,
          left = (Ember.$(window).width() - width) / 2,
          top = (Ember.$(window).height() - height) / 2,
          url = 'https://twitter.com/share?url=' + url + '&text=' + twitterMsm,
          opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
      window.open(url, 'twitter', opts);
      window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_TWITTER_COMPARTIR_COMUNIDAD, comunidad_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_TWITTER);
    },
    linkedin: function linkedin(e) {
      this._super();

      var self = this;
      var aTagCurso = Ember.$(e.target).parent().parent().parent().parent().parent().children()[0];
      var arrayComunidad = Ember.$(aTagCurso).data('href').split('/');
      var comunidad_k = arrayComunidad[arrayComunidad.length - 1];
      shareLI(constant.SHAREURL + 'comunidad/' + comunidad_k);
      window.add_user_action(parseInt(self.session.userData.usuario_k), 4, self.session.userData.nombre, constant.LOGROS.CONSTANTES.TABLA_PROPIETARIO_LINKEDIN_COMPARTIR_COMUNIDAD, comunidad_k, constant.LOGROS.CONSTANTES.RED_SOCIAL_LINKEDIN);
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});